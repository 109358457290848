import React, { useState, useEffect, lazy, Suspense, useContext } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

import { HeroImageRight } from '../components/General/HeroImageRight';
import { HeroVideo } from '../components/General/HeroVideo';
import { Hero } from '../components/General/Hero';
import { Resources } from '../components/General/Resources';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import { SEO } from '../components/SEO';
import { ProductFeatureBody } from '../components/ProductFeature/ProductFeatureBody';
import { FeatureFAQ } from '../components/ProductFeature/FeatureFAQ';
// import { Form } from '../components/ContactForm/Form';
import FeatureThumbnailContext from '../context/FeatureThumbnailContext';
import { ThumbnailFeatureModal } from '../components/General/ThumbnailFeatureModal';
import { SoftwareFeatures } from '../components/Comparisons/SoftwareFeatures';
import { Testimonials } from '../components/Testimonials';
import { CustomerLogos } from '../components/CustomerLogos';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	background: {
		background: theme.white,
	},
	heroVideo: {
		marginBottom: '5rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '3rem',
		},
	},
	softwareFeaturesContainer: {
		paddingTop: '1rem',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '2rem',
    }
	},
	formWaveDown: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-4rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginBottom: '-3rem',
		},
	},
	formWaveUp: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-10rem',
		},
	},

	formCont: {
		marginTop: '4rem',
		padding: '12rem 0 24rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '8rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '5rem 0',
		},
	},
	faqContainer: {
		background: '#f4f8ff',
		paddingBottom: '10rem',
		[theme.breakpoints.down('md')]: {
			marginBottom: '-2rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-5rem',
		},
	},
	testimonialBackground: {
		backgroundSize: 'cover',
		height: '600px',
		width: '99vw',
		maxWidth: '100%',
		[theme.breakpoints.down('md')]: {
			width: '100vw',
			height: '500px',
		},
		[theme.breakpoints.down('sm')]: {
			height: '400px',
		},
		[theme.breakpoints.down('xs')]: {
			backgroundPosition: 'left 60px',
		},
	},
	testimonialContainer: {
		marginTop: '-35rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '-30rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '-25rem',
		},
	},
}));

export default function Comparisons({ data: { comparisons }, location }) {
	const classes = useStyles();

	const {
		_id,
		metaTitle,
		metaDescription,
		marketoId,
		pardotUrl,
		contactForm,
		hero,
		heroImage,
		heroVideo,
		heroAlignCenter,
		formBgImage,
		resourceTitle,
		resources,
		_rawResourceBody,
		faq,
		faqTitle,
		softwareFeatures,
		supportedProductsHeader,
		testimonial,
		testimonialTitle,
		testimonialBackground,
		customerLogos,
		customerLogosHeader,
		header,
		otherCompareName,
		otherCompareLink,
	} = comparisons;
	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			{heroAlignCenter && heroVideo ? (
				<div className={classes.heroVideo}>
					<Hero hero={hero} feature />
					<Container>
						<HeroVideo wistiaLink={heroVideo} />
					</Container>
				</div>
			) : heroAlignCenter ? (
				<Hero hero={hero} feature />
			) : (
				<HeroImageRight
					_id={_id}
					hero={hero}
					heroImage={heroImage?.asset?.gatsbyImageData}
					compare
				/>
			)}

			{/* <WaveUpSVG height='213' width='100%' fill='#fff' /> */}
			{/* <WaveDownSVG height='213' width='100%' fill='#FFFFFF' /> */}
			<Container className={classes.softwareFeaturesContainer}>
				<SoftwareFeatures
					header={header}
					// subheader={supportedProductsSubheader}
					softwareFeatures={softwareFeatures}
					// accentColor={accentColor[0].hexValue}
					otherCompareName={otherCompareName}
					otherCompareLink={otherCompareLink}
				/>
			</Container>
			<WaveDownSVG height='213' width='100%' fill='#FFFFFF' />
			{faq.length ? (
				<div className={classes.faqContainer}>
					<Container>
						<FeatureFAQ header={faqTitle} faq={faq} />
					</Container>
				</div>
			) : null}

			{/* <div>
				<WaveUpSVG height='213' width='100%' fill={'#FFFFFF'} />
			</div> */}
			<div
				className={classes.testimonialBackground}
				style={{
					backgroundImage: `url(${testimonialBackground?.asset.gatsbyImageData.images.fallback.src})`,
				}}
			/>
			<WaveUpSVG fill='#FFF' />
			<div className={classes.testimonialContainer}>
				<Testimonials
					header={testimonialTitle}
					testimonials={testimonial}
					// formRef={formRef}
					background
				/>
			</div>
			<Container>
				<CustomerLogos
					customerLogosArray={customerLogos}
					customerLogosHeader={customerLogosHeader}
				/>
			</Container>
			{/* <WaveDownSVG height='213' width='100%' fill='#FFFFFF' /> */}
			<div
				className={classes.formCont}
				style={{
					backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
				}}>
				<Form
					formId={marketoId}
					pardotUrl={pardotUrl}
					contactForm={contactForm}
					privacy
					modal={false}
					location={location}
				/>
			</div>
			{/* <WaveUpSVG height='213' width='100%' fill='#FFFFFF' /> */}
			<Container>
				<Resources
					header={resourceTitle}
					resources={resources}
					subheader={_rawResourceBody}
				/>
			</Container>
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
			{/* <ThumbnailFeatureModal
				open={modalOpen}
				setFeatureModalOpen={setModalOpen}
				wistiaLink={selectedThumbnail}
				feature
			/> */}
		</>
	);
}

export const query = graphql`
	query ($slug: String!) {
		comparisons: sanityComparisons(slug: { current: { eq: $slug } }) {
			_id
			title
			metaTitle
			metaDescription
			slug {
				current
			}
			hero {
				_rawContent
				backgroundImage {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				mobileBackgroundImage {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				ctaButtonText
				ctaButtonLink
				internalLink
			}
			heroImage {
				asset {
					gatsbyImageData(placeholder: BLURRED)
				}
			}
			heroVideo
			heroAlignCenter
			# softwareFeatureListHeader
			header
			softwareFeatures {
				title
				comparisonHeader
				featureListCta
				logos {
					asset {
						gatsbyImageData
					}
				}
				alternateTextHeaders
				features {
					featureTitle
					featureDescription
					colOne
					colTwo
					comparisonText
					colThree
				}
				expandedListHeader
				expandedListSubheader
				expandText
				hideText
				softwareLogoAndDescription {
					softwareComparisonTitle
					logos {
						asset {
							gatsbyImageData(placeholder: BLURRED, height: 115)
						}
					}
				}
			}
			testimonialTitle
			testimonial {
				title
				header
				testimonialLogo {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				company
				testimonialText
				nameAndTitle
				videoVariant
				image {
					asset {
						gatsbyImageData(
							fit: FILLMAX
							height: 450
							width: 775
							placeholder: BLURRED
						)
					}
				}
			}
			testimonialBackground {
				asset {
					gatsbyImageData(placeholder: BLURRED)
				}
			}
			resourceTitle
			_rawResourceBody
			resources {
				title
				blurb
				image {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
				ctaText
				ctaLink
			}
			formBgImage {
				asset {
					gatsbyImageData(placeholder: BLURRED)
				}
			}
			contactForm {
				header
				privacyPolicy
			}
			marketoId
			pardotUrl
			customerLogosHeader
			customerLogos {
				logo {
					asset {
						gatsbyImageData(placeholder: BLURRED)
					}
				}
			}
			otherCompareName
			otherCompareLink
			faqTitle
			faq {
				question
				_rawAnswer
			}
		}
	}
`;
